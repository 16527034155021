import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/authservice';
import { PeopleService } from 'src/app/services/people/peopleservice';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public focus;
  public listTitles: any[];
  public location: Location;
  constructor(location: Location, private element: ElementRef,
    private router: Router, private authService: AuthService, private peopleService: PeopleService,) {
    this.location = location;
  }

  user: any = {};

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    this.getUserInfo();
  }

  public getUserInfo() {
    this.peopleService.UserProfile().subscribe(
      (data) => {
        this.user = data[0];
      },
      (error) => {
        console.log(error)
      }
    );
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }

  logout() {
    console.log("logged out!");
    this.authService.logout();
    window.location.href = "/#/login"
    // this.router.navigate(['/login']).then(() => {
    //   window.location.reload();
    // });
  }

}
