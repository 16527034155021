import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/authservice';
import { addEmitHelper } from 'typescript';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  onlogin: boolean;
  onStaff: boolean
}
export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'ni-tv-2 text-primary', class: '', onlogin: true, onStaff: true },
  { path: '/register-attendance', title: 'Register attendance', icon: 'ni-single-copy-04 text-primary', class: '', onlogin: true, onStaff: true },
  // { path: '/icons', title: 'Icons',  icon:'ni-planet text-blue', class: '' },
  // { path: '/maps', title: 'Maps',  icon:'ni-pin-3 text-orange', class: '' },
  { path: '/my-attendance', title: 'My attendance', icon: 'ni-bullet-list-67 text-primary', class: '', onlogin: true, onStaff: true },
  { path: '/users', title: 'Users', icon: 'ni-single-02 text-primary', class: '', onlogin: true, onStaff: JSON.parse(localStorage.getItem("staff")) },
  // { path: '/user-profile', title: 'User profile',  icon:'ni-single-02 text-yellow', class: '', onlogin: true  },
  { path: '/login', title: 'Login', icon: 'ni-key-25 text-info', class: '', onlogin: false, onStaff: true },
  { path: '/register', title: 'Register', icon: 'ni-circle-08 text-pink', class: '', onlogin: false, onStaff: true }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => {
      if (menuItem.onlogin) {

        return this.authService.isLoggedIn();
      }
      return this.authService.isLoggedOut();
    }).filter(menuItem2 => {
      if (menuItem2.onStaff) {
        return menuItem2;
      }


    });

    console.log(this.menuItems)
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });


  }

  logout() {
    this.authService.logout();
    window.location.href = "/#/login"
  }

}
