import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { of } from "rxjs";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: "root",
})
export class AuthService {
  urlBase = environment.serverurl;

  authenticated = false;
  mensaje = "";
  constructor(private http: HttpClient) {

  }

  async login(email: string, password: string) {
    try {
      const logindata = await this.http.post<any>(this.urlBase + 'auth/login/', {
        'username': email,
        'password': password
      }).toPromise();
      //console.log(logindata)
      this.setSession(logindata);
      this.authenticated = true;
    }
    catch (error) {
      console.log(error)
      if (error instanceof HttpErrorResponse) {
        this.mensaje = error.error.detail;
        switch (error.status) {
          case 400:
            break;
          case 401: // Clave o usuario incorrecta
            this.mensaje = error.error.detail;
            break;
          case 403: // UsuarioDto cliente intenta logearse en sistema web
            this.mensaje = error.error.detail;
            break;
          case 500:
            break;
          default:
            this.mensaje = "Ha ocurrido un error desconocido.";
        }
      } else {
        this.mensaje = "Ha ocurrido un error.";
      }
      this.authenticated = false;
    }

    return of({ login: this.authenticated, mensaje: this.mensaje });
  }

  async register(modelUserRegister: any) {
    try {
      const logindata = await this.http.post<any>(this.urlBase + 'auth/register/', modelUserRegister).toPromise();
      this.setSession(logindata);
      this.authenticated = true;
    }
    catch (error) {
      if (error instanceof HttpErrorResponse) {
        this.mensaje = error.error.detail;
        switch (error.status) {
          case 400:
            break;
          case 401: // Clave o usuario incorrecta
            this.mensaje = error.error.detail;
            break;
          case 403: // UsuarioDto cliente intenta logearse en sistema web
            this.mensaje = error.error.detail;
            break;
          case 500:
            break;
          default:
            this.mensaje = "Ha ocurrido un error desconocido.";
        }
      } else {
        this.mensaje = "Ha ocurrido un error.";
      }
      this.authenticated = false;
    }

    return of({ login: this.authenticated, mensaje: this.mensaje });
  }

  private setSession(authResult) {
    //console.log(authResult);
    localStorage.setItem('access', authResult.access);
    localStorage.setItem("refresh", authResult.refresh);
    localStorage.setItem("staff", authResult.is_staff.toString());
  }

  logout() {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("staff")
  }

  public isLoggedIn() {
    if (localStorage.getItem("access"))
      return true;
    return false;
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    const expiration = localStorage.getItem("expires_at");
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }
}