import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './authservice';


@Injectable()
export class LoginGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const idToken = localStorage.getItem("access");

        if (this.authService.isLoggedIn()) { // determine if the uder is logged in from this method.
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}