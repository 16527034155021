import { Component, OnInit } from '@angular/core';
import { PeopleService } from 'src/app/services/people/peopleservice';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  data: any[];
  dataready = true;
  inputValue = "";
  isActive: boolean;
  constructor(private peopleService: PeopleService) { }

  ngOnInit(): void {
    this.loadUsers();

  }

  loadUsers() {
    this.peopleService.UserAllProfiles().subscribe(
      (data) => {
        this.dataready = false;
        this.data = data;
        console.log(data);


        this.dataready = true;
      },
      (error) => {
        console.log(error)
      }
    );
  }

  onCheckboxChange(event: any) {
    this.EditUsers(event.target.name, event.target.checked)
  }

  EditUsers(id, is_active) {
    //body={"id":id,"is_active":is_active};
    this.peopleService.UserProfileEditActive({ "id": id, "is_active": is_active }).subscribe(
      (data) => {

        console.log(data)


      },
      (error) => {
        console.log(error)
      }
    );
  }
}

