import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: "root",
})
export class PeopleService {

  urlBase = environment.serverurl + "people/";

  constructor(private http: HttpClient) {
  }

  buildurl(path: string) {
    return this.urlBase + path;
  }

  UserAllProfiles(): Observable<any> {
    return this.http.get<any>(this.buildurl('userprofilesall/?page_size=0'));
  }

  UserProfile(): Observable<any> {
    return this.http.get<any>(this.buildurl('userprofiles/?page_size=0'));
  }

  Offices(): Observable<any> {
    return this.http.get<any>(this.buildurl('office/?page_size=0'));
  }

  Companies(): Observable<any> {
    return this.http.get<any>(this.buildurl('companygroup/?page_size=0'));
  }

  UserNames(term) {
    if (term === '') {
      return of([]);
    }

    return this.http.get<any>(this.buildurl('user/names/?page_size=0') + '&filter=' + term);
  }

  UserProfileEdit(body): Observable<any> {
    return this.http.put<any>(this.buildurl('userprofiles/') + body.id + '/', body);
  }

  UserProfileEditActive(body): Observable<any> {
    return this.http.put<any>(this.buildurl('updateuserstatus/') + body.id + '/', body);
  }

}